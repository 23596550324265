// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-aldo-milano-js": () => import("./../../../src/pages/aldo-milano.js" /* webpackChunkName: "component---src-pages-aldo-milano-js" */),
  "component---src-pages-compras-estado-static-js": () => import("./../../../src/pages/compras-estado-static.js" /* webpackChunkName: "component---src-pages-compras-estado-static-js" */),
  "component---src-pages-concesion-obra-publica-static-js": () => import("./../../../src/pages/concesion-obra-publica-static.js" /* webpackChunkName: "component---src-pages-concesion-obra-publica-static-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-contencioso-administrativo-static-js": () => import("./../../../src/pages/contencioso-administrativo-static.js" /* webpackChunkName: "component---src-pages-contencioso-administrativo-static-js" */),
  "component---src-pages-demo-js": () => import("./../../../src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-derecho-administrativo-static-js": () => import("./../../../src/pages/derecho-administrativo-static.js" /* webpackChunkName: "component---src-pages-derecho-administrativo-static-js" */),
  "component---src-pages-derecho-ambiental-urbanistico-static-js": () => import("./../../../src/pages/derecho-ambiental-urbanistico-static.js" /* webpackChunkName: "component---src-pages-derecho-ambiental-urbanistico-static-js" */),
  "component---src-pages-derecho-constitucional-static-js": () => import("./../../../src/pages/derecho-constitucional-static.js" /* webpackChunkName: "component---src-pages-derecho-constitucional-static-js" */),
  "component---src-pages-derecho-de-competencia-static-js": () => import("./../../../src/pages/derecho-de-competencia-static.js" /* webpackChunkName: "component---src-pages-derecho-de-competencia-static-js" */),
  "component---src-pages-derecho-farmaceutico-static-js": () => import("./../../../src/pages/derecho-farmaceutico-static.js" /* webpackChunkName: "component---src-pages-derecho-farmaceutico-static-js" */),
  "component---src-pages-derecho-minero-static-js": () => import("./../../../src/pages/derecho-minero-static.js" /* webpackChunkName: "component---src-pages-derecho-minero-static-js" */),
  "component---src-pages-derecho-municipal-static-js": () => import("./../../../src/pages/derecho-municipal-static.js" /* webpackChunkName: "component---src-pages-derecho-municipal-static-js" */),
  "component---src-pages-derechos-consumidor-static-js": () => import("./../../../src/pages/derechos-consumidor-static.js" /* webpackChunkName: "component---src-pages-derechos-consumidor-static-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-iuspublik-js": () => import("./../../../src/pages/iuspublik.js" /* webpackChunkName: "component---src-pages-iuspublik-js" */),
  "component---src-pages-maria-lourdes-echandi-js": () => import("./../../../src/pages/maria-lourdes-echandi.js" /* webpackChunkName: "component---src-pages-maria-lourdes-echandi-js" */),
  "component---src-pages-publicacion-js": () => import("./../../../src/pages/publicacion.js" /* webpackChunkName: "component---src-pages-publicacion-js" */),
  "component---src-pages-publicaciones-js": () => import("./../../../src/pages/publicaciones.js" /* webpackChunkName: "component---src-pages-publicaciones-js" */),
  "component---src-pages-resolucion-alterna-conflicos-static-js": () => import("./../../../src/pages/resolucion-alterna-conflicos-static.js" /* webpackChunkName: "component---src-pages-resolucion-alterna-conflicos-static-js" */),
  "component---src-pages-servicios-publicos-static-js": () => import("./../../../src/pages/servicios-publicos-static.js" /* webpackChunkName: "component---src-pages-servicios-publicos-static-js" */),
  "component---src-templates-content-js": () => import("./../../../src/templates/content.js" /* webpackChunkName: "component---src-templates-content-js" */),
  "component---src-templates-publications-js": () => import("./../../../src/templates/publications.js" /* webpackChunkName: "component---src-templates-publications-js" */)
}

